import React from 'react';
import { Paper, TextField, Box } from '@mui/material';
import { ExtractionResult } from '../types';

interface ExtractedDataProps {
  data: ExtractionResult[];
  onHover: (index: number | null) => void;
}

const ExtractedData: React.FC<ExtractedDataProps> = ({ data, onHover }) => {
  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      {data.map((item, index) => (
        <TextField
          key={index}
          label={item.alias}
          value={item.answer || ''}
          fullWidth
          margin="normal"
          variant="outlined"
          InputProps={{
            readOnly: true,
            endAdornment: (
              <Box component="span" sx={{ ml: 1, color: 'text.secondary' }}>
                {item.confidence !== undefined && item.confidence !== null
                  ? `${item.confidence.toFixed(2)}%`
                  : 'N/A'}
              </Box>
            ),
          }}
          onMouseEnter={() => onHover(index)}
          onMouseLeave={() => onHover(null)}
        />
      ))}
    </Paper>
  );
}

export default ExtractedData;
