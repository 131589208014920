import React, { useState } from 'react';
import { Container, Typography, Box, Grid, Tabs, Tab, Button, Alert, Tooltip } from '@mui/material';
import FileUpload from './components/FileUpload';
import ExtractedData from './components/ExtractedData';
import ImageViewer from './components/ImageViewer';
import { ExtractionResult } from './types';
import axios from 'axios';

interface FileData {
  file: File;
  extractedData: ExtractionResult[];
  emptyFields: string[];
}

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

const App: React.FC = () => {
  const [filesData, setFilesData] = useState<FileData[]>([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleExtractedData = (data: ExtractionResult[], file: File) => {
    const emptyFields = data
      .filter(result => !result.answer || result.answer.trim() === '')
      .map(result => result.alias);
    setFilesData(prev => [...prev, { file, extractedData: data, emptyFields }]);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const handleDownload = async () => {
    setIsDownloading(true);
    try {
      const validFiles = filesData.filter(fd => fd.emptyFields.length === 0);
      const payload = {
        files: validFiles.map(fd => ({
          filename: fd.file.name,
          extraction_results: fd.extractedData
        }))
      };

      if (payload.files.length === 0) {
        throw new Error('No valid files to download');
      }

      const response = await axios.post(`${API_URL}/api/files/generate-output`, payload, {
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'extracted_data.txt');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error downloading file:', error);
      alert(error instanceof Error ? error.message : 'An error occurred while downloading the file');
    } finally {
      setIsDownloading(false);
    }
  };

  const validFileCount = filesData ? filesData.filter(fd => fd.emptyFields.length === 0).length : 0;

  return (
    <Container maxWidth={false}>
      <Typography variant="h4" component="h1" gutterBottom>
        Document Processing Platform
      </Typography>
      <Box display="flex" alignItems="center" mb={2}>
        <FileUpload onExtractedData={handleExtractedData} />
        <Box display="flex" alignItems="center" ml={2}>
          <Tooltip title={validFileCount === 0 ? 'No valid files to download' : ''}>
            <span>
              <Button
                variant="contained"
                onClick={handleDownload}
                disabled={validFileCount === 0 || isDownloading}
              >
                {isDownloading ? 'Downloading...' : 'Download Output File'}
              </Button>
            </span>
          </Tooltip>
          {filesData && filesData.length > 0 && filesData[currentTab]?.emptyFields.length > 0 && (
            <Alert severity="warning" sx={{ ml: 2 }}>
              <Typography variant="body2">
                {`${filesData[currentTab].file.name} has ${filesData[currentTab].emptyFields.length} empty field(s): ${filesData[currentTab].emptyFields.join(', ')}. This file will not be included in the output file.`}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                
              </Typography>
            </Alert>
          )}
        </Box>
      </Box>
      {filesData && filesData.length > 0 && (
        <Box mt={4}>
          <Tabs 
            value={currentTab} 
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            {filesData.map((fileData, index) => (
              <Tab 
                key={index} 
                label={fileData.file.name} 
                sx={{
                  maxWidth: 200,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              />
            ))}
          </Tabs>
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <ExtractedData 
                  data={filesData[currentTab].extractedData} 
                  onHover={setHoveredIndex} 
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <ImageViewer 
                  file={filesData[currentTab].file} 
                  extractedData={filesData[currentTab].extractedData} 
                  hoveredIndex={hoveredIndex} 
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </Container>
  );
}

export default App;
