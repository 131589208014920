import React, { useRef, useEffect } from 'react';
import { ExtractionResult } from '../types';

interface ImageViewerProps {
  file: File;
  extractedData: ExtractionResult[];
  hoveredIndex: number | null;
}

const ImageViewer: React.FC<ImageViewerProps> = ({ file, extractedData, hoveredIndex }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (file && extractedData) {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0);

        extractedData.forEach((item, index) => {
          if (item.bounding_box) {
            const { left, top, width, height } = item.bounding_box;
            ctx.strokeStyle = index === hoveredIndex ? 'yellow' : 'red';
            ctx.lineWidth = 2;
            ctx.strokeRect(
              left * image.width,
              top * image.height,
              width * image.width,
              height * image.height
            );

            if (index === hoveredIndex) {
              ctx.fillStyle = 'rgba(255, 255, 0, 0.3)';
              ctx.fillRect(
                left * image.width,
                top * image.height,
                width * image.width,
                height * image.height
              );
            }
          }
        });
      };
    }
  }, [file, extractedData, hoveredIndex]);

  return <canvas ref={canvasRef} style={{ maxWidth: '100%', height: 'auto' }} />;
}

export default ImageViewer;
