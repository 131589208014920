import React, { useState, useRef, ChangeEvent } from 'react';
import { Button, CircularProgress, Box, Alert, Snackbar } from '@mui/material';
import axios from 'axios';
import { Query, ExtractionResult } from '../types';

interface FileUploadProps {
  onExtractedData: (data: ExtractionResult[], file: File) => void;
}

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

const FileUpload: React.FC<FileUploadProps> = ({ onExtractedData }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setIsLoading(true);
      setUploadProgress(0);
      const totalFiles = files.length;
      
      for (let i = 0; i < files.length; i++) {
        await handleFileUpload(files[i]);
        setUploadProgress(((i + 1) / totalFiles) * 100);
      }
      
      setIsLoading(false);
      setUploadProgress(0);
    }
  };

  const handleFileUpload = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      // First, upload the file
      const uploadResponse = await axios.post<{ s3_file_path: string }>(`${API_URL}/api/files/upload`, formData);
      console.log('File uploaded:', uploadResponse.data);
      const filePath = uploadResponse.data.s3_file_path;

      // Then, extract data
      const queries: Query[] = [
        { query: "What is the form name or title?", alias: "form_name" },
        { query: "What is the UT account number?", alias: "account_number" },
        { query: "What is the fund code?", alias: "fund_code" },
        { query: "What is the PTE?", alias: "pte" },
        { query: "What is the trade date?", alias: "trade_date" },
        { query: "What is the dividend instruction?", alias: "dividend_instruction" },
        { query: "What is the fund currency?", alias: "fund_currency" },
        { query: "What is the investment amount?", alias: "investment_amount" },
        // { query: "How many number of units?", alias: "number_of_units" },
        { query: "What is the discount or Disc%?", alias: "discount_percentage" },
        // { query: "What is the payment mode?", alias: "payment_mode" }
      ];

      const extractionResponse = await axios.post<ExtractionResult[]>(`${API_URL}/extract`, {
        file_path: filePath,
        queries: queries
      });

      const emptyFields = extractionResponse.data
        .filter(result => !result.answer || result.answer.trim() === '')
        .map(result => result.alias);

      if (emptyFields.length > 0) {
        const message = `The following fields have no value: ${emptyFields.join(', ')}`
        setAlertMessage(message)
        setAlertOpen(true)
      }

      onExtractedData(extractionResponse.data, file);
    } catch (error) {
      console.error('Error processing file:', error);
      setAlertMessage('Error processing file. Please try again.');
      setAlertOpen(true);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <Box>
      <input
        ref={fileInputRef}
        accept="image/*"
        style={{ display: 'none' }}
        type="file"
        onChange={handleFileSelect}
        multiple
      />
      <Button
        variant="contained"
        onClick={handleButtonClick}
        disabled={isLoading}
      >
        {isLoading ? (
          <>
            Uploading ({uploadProgress.toFixed(0)}%)
            <CircularProgress size={24} sx={{ ml: 1, color: 'inherit' }} />
          </>
        ) : (
          'Upload Files'
        )}
      </Button>
      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="warning" sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default FileUpload;
